import AutocompleteAddress from './external/AutocompleteAddress'

import Block from '../../common.blocks/block'

export default class AddressAutocomplete extends Block {
	static title = 'address-autocomplete'

	constructor($el) {
		super($el)
		this.elInput = this.$el.findElement('input')
		this.initializeAutocomplete()
	}

	initializeAutocomplete() {
		if (!this.elInput.length) {
			// eslint-disable-next-line no-console
			console.warn(`${AddressAutocomplete.title} is missing the input element`)
			return
		}

		this.toggleErrorMessage(true)

		this.autocomplete = new AutocompleteAddress(this.elInput[0], {
			autocompleteUrl: '/account/address/autocomplete',
			addressDetailsUrl: '/account/address/details',
			context: 'deu',
			language: 'de-DE',
			// Enforce use of delay. According to docs first 1-3 characters don't take delay in consideration.
			minLength: 4,
			delay: 500,
		})

		this.$el.on('autocomplete-select', (e) => {
			if (e.detail.precision === 'Address') {
				this.autocomplete.getDetails(e.detail.context, (result) => {
					this.toggleErrorMessage(false)

					const convertedAddress = this.convertAddress(result.address)
					this.setHiddenInputData(convertedAddress)
				})
			}
		})

		this.$el.on('autocomplete-error', (e) => {
			const json = JSON.parse(e.detail.request.responseText)
			$(document).trigger('message:error', json.error)
		})

		this.$el.on('autocomplete-search', () => {
			this.toggleErrorMessage(true)
		})
	}

	toggleErrorMessage(on) {
		if (this.elInput.prop('required')) {
			this.setInputErrorMessage(on ? this.$el.data('errorMsg') : '')
		}
	}

	setInputErrorMessage(message) {
		this.elInput[0].setCustomValidity(message)
	}

	setHiddenInputData(address) {
		for (const [name, value] of Object.entries(address)) {
			this.$el.find(`input[name="${name}"]`).val(value)
		}
	}

	convertAddress(address) {
		return {
			street: address.street,
			number: address.building,
			zipcode: address.postcode,
			city: address.locality,
			country_id: address.country_id,
		}
	}
}
